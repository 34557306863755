<template>
  <div>
  <div class="app">
  
    <NavigationBar/>
    <div class="container"  style="width: 100%; padding: 0; margin: 0; padding: 0; font-family: Verdana, Geneva, Tahoma, sans-serif;">

    
    <router-view />
    <div id="my-chat-container"></div> 
  </div>
  <hr />

</div>
  </div>
</template>


<script>

import NavigationBar from './components/Navigation.vue'
import 'bootstrap/dist/css/bootstrap.css'


export default {
  name: "app",
  components: { NavigationBar },
 
};
(function () { 
    const script = document.createElement('script'); 
    script.async = true; 
    script.src = 'https://tcl.twin24.ai/widget/v3/manual.umd.min.js'; 
    document.head.appendChild(script); 
    script.onload = () => { 
      window.TChat.init('#my-chat-container', { 
        chatId: '3faac69c-9c78-4d08-a9a2-3030165c8af9', 
      }).then(client => window.TChatClient = client); 
    }; 
  })();


</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
//@import url("https://fonts.googleapis.com/css2?family-Raleway:wght@400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 200;
  margin: 0 auto;
  @media (min-width: 1140px) {
      max-width: 1140px;
    }


}

.app {
  min-height: 100vh;
  position: relative;
  background-color: #f1f1f1;
}
.container {
  padding-left: 50;
  //padding-top: 50px;
  align-items: center;
  //max-width: 1140px;
  margin: 0;
}

.body {
  background-attachment: fixed;
  padding: 0;
  margin: 0;
}
</style>