//import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import vuetify from 'vuetify'
//import "bootstrap/dist/css/bootstrap.min.css"
///import bootstrap from "bootstrap"




createApp(App).use(store).use(router).use(VueScrollTo).use(vuetify).mount('#app')
