<template>
  <div class="container" style="padding-top: 96px">
    <div class="row col-12 mt-5 mb-2">
      <div class="col-6" style="max-width: 450px;">
        <img style="max-width: 450px;
        width: 100%;
          HEIGHT: 100%;
          " src="../assets/prog3.jpeg" alt="">
        <br/>
        <br/>
        <span style="font-size: 18px; color: red">ПОМОЖЕМ СЕМЬЯМ</span><br />
        В данном случае суть программ в сборе средств для малообеспеченных и
        многодетных семей на предметы первой необходимости - еду и одежду. Так
        же некоторые программы направлены на улучшение качества жизни таких
        семей - косметический или капитальный ремонт жилого помещения
      </div>
      <div class="col-6" style="max-width: 450px;">
        <img style="max-width: 450px;
        width: 100%;
          HEIGHT: 100%;
          " src="../assets/prog2.jpeg" alt="">
        <br/>
        <br/>
        <span style="font-size: 18px; color: red">РУКА ПОМОЩИ</span><br />

        Наш фонд занимается срочным сбором средств для детей и взрослых на
        проведение диагностических процедур, дорогостоящих и высокотехнологичных
        операций, а также реабилитацию всех возможных тяжелых и редких
        заболеваний.
      </div>
    </div>
    
    <div class="row col-12 mt-3 mb-5">
      <div class="col-6" style="max-width: 450px;">
        <img style="max-width: 450px;
        width: 100%;
          HEIGHT: 100%;
          " src="../assets/prog4.jpeg" alt="">
        <br/>
        <br/>
        <span style="font-size: 18px; color: red">ШАГ В БУДУЩЕЕ
        </span><br />
        Стипендиальная программа для выпускников школ детей из малообеспеченных семей и сирот, которая поможет им получить профессию мечты в высшем учебном заведении, а регион пополнится высоко квалифицированными специалистами.
      </div>
      <div class="col-6" style="max-width: 450px;">
        <img style="max-width: 450px;
        width: 100%;
          HEIGHT: 100%;
          " src="../assets/prog1.jpeg" alt="">
        <br/>
        <br/>
        <span style="font-size: 18px; color: red">ДОСТУПНАЯ СРЕДА</span><br />
        Большое количество наших благотворительных программ направлены на повышение доступности объектов социальной среды для инвалидов, для создания для них более доступного и комфортного окружения, что в разы повышает качество жизни и ускоряет возвращение в социум
      </div>
    </div>
    <br/>
    <br/>
    <div
      class="row col-12"
      style="position: relative;"
    >
      <span style="text-align:center;">
        Именно ваша помощь и участие спасет множество и множество хрупких и
        таких важных жизней и обязательно изменит их к лучшему. Нет ничего
        проще, чем просто начать помогать!
      </span>
    </div>
    <br/>
    <br/>
    
  </div>
</template>
  
  <script>
export default {
  name: "ProgramItem",
  props: {
    msg: String,
  },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
a {
  color: #42b983;
}
</style>
  