<template>
   <div class="container">
      <h1>
      Здесь скоро будет личный кабинет.  
      </h1>

    </div>   
   
  </template>
  
  <script>
  export default {
    name: "ProFile",
    props: {
      msg: String,
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  a {
    color: #42b983;
  }
  </style>
  