import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomeView.vue';
import ProgramItem from '../views/ProgramView.vue';
import OfertaItem from '../views/OfertaView.vue';
import AboutFond from '../views/AboutView.vue';
import ProFile from '../views/ProfileView.vue';
import CyndykItem from '../views/CyndykView.vue';
import ReportFond from '../views/ReportView.vue';
import DocsFond from '../views/DocsView.vue';
//import PrivacyItem from '../views/PrivacyView.vue';
import NeedHelpItem from '../views/NeedHelpView.vue';
import StepItem from '../views/StepView.vue';
import SovetItem from '../views/SovetView.vue';
import VolunteerItem from '../views/VolunteerView.vue';

// 1. Определяем компоненты для маршрутов.
// Они могут быть импортированы из других файлов
//const HomePage = { template: '<div>HomePage</div>' }
//const AboutFond = { template: '<div>about</div>' }

const routes = [
  {
    path: '/',
    component: HomePage
  },
  {
    path: '/program',
    component: ProgramItem
  },
  {
    path: '/docs/oferta/:t_id',
    component: OfertaItem
  },
  /*{
    path: '/docs/privacy',
    component: PrivacyItem
  },
  */{
    path: '/Docs',
    component: DocsFond
  },
  {
    path: '/about',
    component: AboutFond
  },
  {
    path: '/profile',
    component: ProFile
  },
  {
    path: '/cyndyk',
    component: CyndykItem
  },
  {
    path: '/step',
    component: StepItem
  },
  {
    path: '/report',
    component: ReportFond
  },
  {
    path: '/needhelp',
    component: NeedHelpItem
  },
  {
    path: '/sovet',
    component: SovetItem
  },
  {
    path: '/volunteer',
    component: VolunteerItem
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash'
})

export default router
