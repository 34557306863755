<template>
    <div class="container" style="padding-top: 96px;">
      <br><br><br><br><br><br><br><br><br><br><br><br>
      <span style = "color: black;">
      {{ this.pParams }}
    </span>
    </div>
  </template>
  
  <script>
  export default {
    name: "OfertaItem",
    props: {
      msg: String,
    },
    data() {
      return {
        pParams: null,

      }  
      },
    created() {

      console.log(this.$route);
      let uri = window.location.search;
      
      console.log('1 - ' + this.uri);

      this.pParams = new URLSearchParams(uri);
    console.log('2 - ' + this.pParams);

    }

  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  a {
    color: #42b983;
  }
  </style>
  