<template>
  <div
    class="container"
    style="
      padding-top: 96px;
      text-align: center;
      padding-left: 50px;
      padding-right: 50px;
    "
  >
    <br />
    <span
      style="font-size: 32px; font-family: Verdana, Geneva, Tahoma, sans-serif"
      >Высший орган управления фонда.</span
    >
    <br />
    <br />
    
<div class="row">
    <div class="container col-5">
      <img
        style="
          align-items: center;
          text-align: center;

          max-height: 350px;
          max-width: 290px;
          width: 100%;
          HEIGHT: 100%;
          background-color: white;
        "
        src="../assets/Бордзиловская.jpg"
      />
      <br />
      <span style="width: 100%;max-width: 290px;">Бордзиловская Татьяна Владимировна<br/>Президент Фонда</span>
    </div>
   </div>

    <br />
<div class="row">
  <div class="container col-5 offset-1">
      <img
        style="
          align-items: center;
          text-align: center;
          max-height: 350px;
          max-width: 290px;
          width: 100%;
          HEIGHT: 100%;
          background-color: white;
        "
        src="../assets/Квасюкова.jpg"
      />
      <br />
      <span>Квасюкова Марина Анатольевна<br/>Совет Фонда</span>
    </div>
  <br/>
    <div class="container col-5 offset-1">
      <img
        style="
          align-items: center;
          text-align: center;
          max-height: 350px;
          max-width: 290px;
          width: 100%;
          HEIGHT: 100%;
          background-color: white;
        "
        src="../assets/Чернышова.jpg"
      />
      <br />
      <span>Чернышева Татьяна Александровна<br/>Совет Фонда</span>
    </div>
  </div>
  </div>
</template>
    
    <script>
export default {
  name: "SovetItem",
  props: {
    msg: String,
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
a {
  color: #42b983;
}
</style>
    