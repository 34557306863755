<template>
  <div>
    <section id="sMain">
      <div
        class="container"
        style="
          display: block;
          position: relative;
          
          align-items: center;
          text-align: center;
          padding-top: 96px;
        "
      >
      
              <template v-if="!this.isMobile">
            
          <div class="row col-12">
            <div class="col-6">
              <img
                style="
                  max-width: 500px;
                  transition: 0.5s ease all;
                  padding-top: 50px;
                "
                src="../assets/logo-grey.png"
                alt=""
              />
            </div>
            <div class="col-6">
              <div class="container"></div>
            </div>
          </div>
          <br />
          <br />
          <br />
              </template>
        <template v-else>
          
          <div class="col-12">
            <img
              style="
                max-width: 400px;
                transition: 0.5s ease all;
                padding-top: 50px;
              "
              src="../assets/logo-grey.png"
              alt=""
            />
          </div>
          <br/>
        </template>
      </div>
    </section>

    <template v-if="!this.isMobile">
      
      <div class="row col-12" style="width: 100%" >
        <div class="col-4">
          <router-link class="link" to="./program">
            <div style="">
            <button
              style="
                background-color: white;
                border: 2px solid;
                border-color: red;
                color: red;
                width: 380px;
                height: 70px;
                font-size: 24px;
                text-decoration: none;
                text-align: center;
              "
            >
              Кому помочь
            </button>
          </div>
          </router-link>
        </div>

        <div class="col-4">
          <button
            v-scroll-to="'#spay'"
            style="
              background-color: white;
              border: 2px solid;
              border-color: red;
              color: red;
              width: 380px;
              height: 70px;
              font-size: 24px;
              
            "
          >
            Помочь Фонду
          </button>
        </div>

        <div class="col-4">
          <button
            v-scroll-to="'#pomosch'"
            style="
              background-color: white;
              border: 2px solid;
              border-color: red;
              color: red;
              width: 380px;
              height: 70px;
              font-size: 24px;
              
            "
          >
            Чем помочь
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      
      <div
        class="container"
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
        "
      >
        <router-link class="link" to="./program">
          <button
          style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 300px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
        "
          >
            Кому помочь
          </button>
        </router-link>

        <button
          v-scroll-to="'#spay'"
          style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 300px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
        "
        >
          Помочь Фонду
        </button>

        <button
          v-scroll-to="'#pomosch'"
          style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 300px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
        "
        >
          Чем помочь
        </button>
        <br/>
      </div>
    </template>
    <section
      id="miss"
      style="background-color: white; width: 100%; padding: 0; margin: 0"
    >
      <div
        class="container"
        style="background-color: white; width: 100%; padding: 10px; margin: 0"
      >
        <template v-if="!this.isMobile">
          <div
          class="row"
          style="background-color: white; width: 100%; padding: 0; margin: 0"
        >
          <div class="col-6">
            <span style="margin-top: 50px">
              <br /><br /><br /><br /><br />
              Миссией нашего благотворительного фонда, является помощь всем
              нуждающимся и находящимся в трудных жизненных ситуациях, соц.
              поддержка, защита и реабилитация социально незащищенных категорий
              граждан. <br />
              Наш благотворительный фонд основан в декабре 2023 года, но каждый
              из нас работает в благотворительности уже не первый год и обладает
              собственным уникальным опытом. <br />
              Наша деятельность, в основном, направлена на помощь взрослым,
              многодетным и малообеспеченным семьям, людям со сложными
              заболеваниями, инвалидам. <br />
              Так же мы работаем над улучшением психологического климата в нашем
              регионе, содействуем в программах экологической направленности,
              развитии ремесел, сохранении истории, восстановлении памятников
              старины. <br />
              Так же мы открываем стипендиальную программу для выпускников школ
              Кировского района Ставропольского края, детей из малообеспеченных
              семей и сирот, которая поможет им получить профессию мечты в
              высшем учебном заведении.
              <br /><br /><br /><br /><br /><br />
            </span>
          </div>
          <div class="col-5 offset-1">
            <br /><br /><br /><br />
            <img
              style="
                max-width: 400px;
                width: 100%;
                transition: 0.5s ease all;
                margin-top: 0px;
                padding: 0px;
              "
              src="../assets/heart.png"
              alt=""
            />
          </div>
        </div>

        </template>
        <template v-else>

          <div class="col-12">
            <span style="margin-top: 50px">
              <br /><br /><br /><br /><br />
              Миссией нашего благотворительного фонда, является помощь всем
              нуждающимся и находящимся в трудных жизненных ситуациях, соц.
              поддержка, защита и реабилитация социально незащищенных категорий
              граждан. <br />
              Наш благотворительный фонд основан в декабре 2023 года, но каждый
              из нас работает в благотворительности уже не первый год и обладает
              собственным уникальным опытом. <br />
              Наша деятельность, в основном, направлена на помощь взрослым,
              многодетным и малообеспеченным семьям, людям со сложными
              заболеваниями, инвалидам. <br />
              Так же мы работаем над улучшением психологического климата в нашем
              региона, содействуем в программах экологической направленности,
              развитии ремесел, сохранении истории, восстановлении памятников
              старины. <br />
              Так же мы открываем стипендиальную программу для выпускников школ
              Кировского района Ставропольского края, детей из малообеспеченных
              семей и сирот, которая поможет им получить профессию мечты в
              высшем учебном заведении.
              <br /><br />
            </span>
          </div>
          <div class="col-12" style="align-items: center;
                text-align: center;
                ">
            <br /><br /><br />
            <img
              style="
                max-width: 400px;
                width: 70%;
                transition: 0.5s ease all;
                margin-top: 0px;
                padding: 0px;
              "
              src="../assets/heart.png"
              alt=""
            />
          </div>
          </template>
      


        
        </div>
      <template v-if="!this.isMobile">
        <div class="container" style="font-size: 24px; padding: 0">
          <div class="row col-12" style="justify-content: center">
            <div class="col" style="text-align: center">
              <span style="font-size: 32px">32</span><br /><span
                style="font-size: 18px"
                >года<br
              /></span>

              <span style="font-size: 12px"
                ><br />
                <p>
                  Совместный опыт работы нашей команды в благотворительности
                </p></span
              >
            </div>
            <div
              class="col"
              style="
                border-left: 5px solid;
                border-color: red;
                text-align: center;
              "
            >
              <span style="font-size: 32px">217</span><br /><span
                style="font-size: 18px"
                >подопечных<br
              /></span>
              <span style="font-size: 12px"
                ><br />
                <p>Ждут нашей помощи прямо сейчас</p></span
              >
            </div>
            <div
              class="col"
              style="
                border-left: 5px solid;
                border-color: red;
                text-align: center;
              "
            >
              <span style="font-size: 32px">15</span><br /><span
                style="font-size: 18px"
                >проектов<br
              /></span>
              <span style="font-size: 12px"
                ><br />
                <p>Ждут вашего участия и помощи</p></span
              >
            </div>
            <div
              class="col"
              style="
                border-left: 5px solid;
                border-color: red;
                text-align: center;
              "
            >
              <span style="font-size: 32px">320 000</span><br /><span
                style="font-size: 18px"
                >рублей<br
              /></span>
              <span style="font-size: 12px"
                ><br />
                <p>
                  Мы планируем собрать с вашей помощью для реализации
                  стипендиальной программы
                </p></span
              >
            </div>
          </div>
        </div>
      </template>
      <br />
      <br />
    </section>


      <div class="container" style="text-align: center;">
        <br/>
   <!--     <button
        v-scroll-to="'#spay'"
        style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 300px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
        "
      >
        Помочь Фонду
      </button>
   
        <section id="spay">
          <div class="row col-12"> 

          <div class="hero-text container col-6">
            <br />

            <form
              class="payform-tinkoff"
              name="payform-tinkoff"
              onsubmit="pay(this); return false;"
            >
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="terminalkey"
                value="1699375114876"
              />
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="frame"
                value="false"
              />
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="language"
                value="ru"
              />
              <label style="color: #000"
                >Сумма пожертвования<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="Сумма"
                  name="amount"
                  required
              /></label>
              <input
                class="payform-tinkoff-row"
                type="hidden"
                placeholder="Номер"
                name="order"
              />
              <label style="color: #000"
                >Назначение платежа<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="Добровольное пожертование"
                  name="description"
                  disabled="true"
              /></label>
              <label style="color: #000"
                >Плательщик<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="ФИО плательщика"
                  name="name"
              /></label>
              <label style="color: #000"
                >Email:<input
                  class="payform-tinkoff-row"
                  type="email"
                  placeholder="E-mail"
                  name="email"
              /></label>
              <label style="color: #000"
                >Телефон:<input
                  class="payform-tinkoff-row"
                  type="tel"
                  placeholder="Контактный телефон"
                  name="phone"
              /></label>
              <input
                class="payform-tinkoff-row payform-tinkoff-btn"
                type="submit"
                value="Поддержать"
                textarea
                :disabled="!oferta_checked"
              />
            </form>
            <label style="color: #000; vertical-align: top">
              <input
                type="checkbox"
                name="checkbox-example"
                v-model="oferta_checked"
                v-on:click="oferta_check"
              />
              <span class="custom-checkbox" style="vertical-align: top"></span>
              Я согласен с
              <a href="../oferta.pdf" style="text-decoration: none"> условиями оферты</a><br/>
              и ознакомлен с <a href="../policy.pdf" target="_blank" style="text-decoration: none;">политикой обработки персональных данных</a>
            </label>
            <br />
            <br />
          </div>
          <template v-if="!this.isMobile">
            <div class="container col-5" style="vertical-align: center;">
              <br>
              <br>
              <img src="../assets/QR-kod.png" alt="Укажи свою сумму и плати" style="width: 70%;">
          </div>
        </template>
        <template v-else>
          <div class="container col-12" style="vertical-align: center;">
              <br>
              <br>
              <img src="../assets/QR-kod.png" alt="Укажи свою сумму и плати" style="width: 70%;">
          </div>

        </template>
          </div>
          
    </section>
  -->
  </div>
  
    <section id="pomosch">
      <div class="container" style="background-color: red">
        <br />
        <br />
        <template v-if="!this.isMobile">
          <div class="row col-12">
            <div class="col-5 offset-1">
              <span style="color: white"> КАК ВЫ МОЖЕТЕ ПОМОЧЬ? </span
              ><br /><br />
              <span style="color: white">
                Нашему фонду всегда нужна ваша помощь, ведь мы расширяемся и
                стараемся помочь детям и взрослым по всему миру! Если у вас есть
                возможность пожертвовать сумму, пусть это будет любая сумма, она
                обязательно дойдет до адресата и поможет спасти жизнь. Если же
                вы хотите лично участвовать в помощи, нам всегда необходимы
                волонтеры!
              </span>
            </div>
            <div class="col-5 offset-1">
              <img
                style="
                  max-width: 400px;
                  width: 100%;
                  transition: 0.5s ease all;
                  margin-top: 0px;
                  padding: 0px;
                "
                src="../assets/heart4.png"
                alt=""
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-12" style="text-align: center;">
            <span style="color: white"> КАК ВЫ МОЖЕТЕ ПОМОЧЬ? </span
            ><br /><br />
            <span style="color: white">
              Нашему фонду всегда нужна ваша помощь, ведь мы расширяемся и
              стараемся помочь детям и взрослым по всему миру! Если у вас есть
              возможность пожертвовать сумму, пусть это будет любая сумма, она
              обязательно дойдет до адресата и поможет спасти жизнь. Если же вы
              хотите лично участвовать в помощи, нам всегда необходимы
              волонтеры!
            </span>
          </div>
            
          <br/>
          <div class="col-12" style="text-align: center;">
            <img
              style="
                max-width: 400px;
                width: 70%;
                transition: 0.5s ease all;
                margin-top: 0px;
                padding: 0px;
              "
              src="../assets/heart4.png"
              alt=""
            />
          </div>

          


        </template>
        <br />
        <br />
      </div>
    </section>
    

  </div>
</template>

<script >


// @ is an alias to /src
export default {
  name: "HomePage",
  components: {},

  data() {
    return {
      oferta_checked: false,
      isMobHom: null,
      isMobile: null,
    };
  },

  
  created() {
    //console.log("1.0");
    this.chScreen;
    //console.log(this.isMobHom);
    // console.log("1");
    

  },
  mounted() {
    
    //console.log("2.0");
    this.chScreen;
    window.addEventListener("resize", this.chScreen);
    window.addEventListener("load", this.chScreen);
    window.addEventListener("orientationchange", this.chScreen);


    //console.log(this.isMobHom);
    //console.log("2");

    //window.addEventListener("submit", this.e);
    //console.log(TPF);
    //window.pay(this);
    /*
    const TPF = document.getElementById("payform-tinkoff");

    TPF.addEventListener("submit", function (e) {
        e.preventDefault();
        const {description, amount, email, phone, receipt} = TPF;

        if (receipt) {
            if (!email.value && !phone.value)
                return alert("Поле E-mail или Phone не должно быть пустым");

            TPF.receipt.value = JSON.stringify({
                "EmailCompany": "mail@mail.com",
                "Taxation": "patent",
                "Items": [
                    {
                        "Name": description.value || "Оплата",
                        "Price": amount.value + '00',
                        "Quantity": 1.00,
                        "Amount": amount.value + '00',
                        "PaymentMethod": "full_prepayment",
                        "PaymentObject": "service",
                        "Tax": "none"
                    }
                ]
            });
        }
        window.pay(TPF);
    })
    */
  },
  methods: {
    
    oferta_check() {
      this.oferta_checked = !this.oferta_checked;
    },
    chScreen() {
      //console.log("3.0");
      if (window.innerWidth <= 750) {
        this.isMobHom = true;
        this.isMobile = true;
      } else {
        this.isMobHom = false;
        this.isMobile = false;
      }
      //console.log("3.1");

      //return this.isMobHom;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-attachment: fixed;
  position: relative;
  height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
}
.wrapper {
    text-align: center;
    text-decoration: none;
}
.payform-tinkoff {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 2px auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 250px;
}
.payform-tinkoff-row {
  margin: 2px;
  border-radius: 4px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border: 1px solid #dfe3f3;
  padding: 15px;
  outline: none;
  background-color: #dfe3f3;
  font-size: 15px;
}
.payform-tinkoff-row:focus {
  background-color: #ffffff;
  border: 1px solid #616871;
  border-radius: 4px;
}
.payform-tinkoff-btn {
  background-color: #fbc520;
  border: 1px solid #fbc520;
  color: #3c2c0b;
}
.payform-tinkoff-btn:hover {
  background-color: #fab619;
  border: 1px solid #fab619;
}
</style> 