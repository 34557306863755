<template>
    <div>
      <div class="container" style="padding-top: 106px;">
    <br/>
            <div class="row col-12"
            style="font-size: 32px; text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">
        <span>Отчеты о деятельности фонда</span>
    </div>
        <br>
        <span style="font-size: 32px;">
            2023
        </span>
        <br/>
        <br/>
        <div class="row col-12 m-2">
        <button 
            style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 150px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
          
        "><a href="../statement_03.12.2023-03.01.2024.pdf" target="_blank" style="text-decoration-line: none;">Декабрь</a>
        </button>
        
        <br>
        <button 
          style="
          background-color: white;
          text-align: center;
          align-items: center;
          border: 1px solid;
          border-color: red;
          color: red;
          width: 250px;
          height: 50px;
          font-size: 24px;
          border-radius: 4px;
          
        "><a href="../report_2023.pdf" target="_blank" style="text-decoration-line: none;">Годовой отчёт</a>
        </button>
                
      </div>
      </div>
    </div>
    
  </template>
  <script>
  
//import axios from 'axios'
//var base_url = "https://cloud-api.yandex.net/v1/disk/public/resources/download?"
//var load_url = "https://disk.yandex.ru/i/m7xxDfLlPhZBgw"

export default {
    name: "ReportFond",
    
    props: {
      msg: String,
    },
  
  
    
    
    methods: {
    fileView() {
        window.open('../statement_03.12.2023-03.01.2024.pdf');
    },

  /*
      methods: {
        async loadFile() {
              await axios({
                    url: base_url + 'public_key=' + load_url,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fURL = document.createElement('a');
    
                     fURL.href = fileURL;
                     fURL.setAttribute('download', 'file.pdf');
                     document.body.appendChild(fURL);
    
                     fURL.click();
                });
          }
          */
      }
    };

    

</script>
  <style lang="scss">
  
  
      </style>