<template>
  <div>
    <div class="container" style="padding-top: 108px;">
      <section id="rekviz">
      <span style="font-size: 32px; text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">Реквизиты</span>
      <br/>
      <br>
      <p>
        Название организации<br/>
БЛАГОТВОРИТЕЛЬНЫЙ ФОНД "АЛАЯ ЛЕНТА"<br/><br/>
Юридический адрес организации<br/>
357324, РОССИЯ, СТАВРОПОЛЬСКИЙ КРАЙ, КИРОВСКИЙ Р-Н, СТ-ЦА ЗОЛЬСКАЯ, УЛ ДАЧНАЯ, Д 2<br/><br/>
ИНН<br/>
2609026129<br/><br/>
КПП<br/>
260901001<br/><br/>
ОГРН/ОГРНИП<br/>
1232600014635<br/><br/>

Реквизиты для платежей:<br/>

Благотворительный фонд "Алая лента"<br/>
ИНН: 2609026129<br/>
КПП: 260901001<br/>
Счет: 40703810112010618862<br/>
в Филиал "Корпоративный" ПАО "Совкомбанк" (г. Москва)<br/>
к/с 30101810445250000360<br/>
БИК 044525360<br/>
SWIFT: SOMRRUMMXXX (для получения переводов в рублях от нерезидентов)
<br/><br/>

<!--
40703810800000738742<br/><br/>
Банк<br/>
АО "ТИНЬКОФФ БАНК"<br/><br/>
ИНН банка<br/>
7710140679<br/><br/>
БИК банка<br/>
044525974<br/><br/>
Корреспондентский счет банка<br/>
30101810145250000974<br/><br/>
Юридический адрес банка<br/>
Москва, 127287, ул. Хуторская 2-я, д. 38А, стр. 26       -->
 <br/><br/>
      </p>
      <hr />
    </section>
    </div>
  </div>
  
  


<!--

  <form class="payform-tinkoff" name="payform-tinkoff" id="payform-tinkoff"
  onsubmit="pay(this); return false;">
    <input class="payform-tinkoff-row" type="hidden" name="terminalkey" value="1699375114876DEMO">
    <input class="payform-tinkoff-row" type="hidden" name="frame" value="false">
    <input class="payform-tinkoff-row" type="hidden" name="language" value="ru">
    <input class="payform-tinkoff-row" type="hidden" name="receipt" value="">
    <input class="payform-tinkoff-row" type="text" placeholder="Сумма заказа" name="amount" required>
    <input class="payform-tinkoff-row" type="hidden" placeholder="Номер заказа" name="order">
    <input class="payform-tinkoff-row" type="text" placeholder="Описание заказа" name="description">
    <input class="payform-tinkoff-row" type="text" placeholder="ФИО плательщика" name="name">
    <input class="payform-tinkoff-row" type="email" placeholder="E-mail" name="email">
    <input class="payform-tinkoff-row" type="tel" placeholder="Контактный телефон" name="phone">
    <input class="payform-tinkoff-row payform-tinkoff-btn" type="submit" value="Оплатить">
</form>
-->
</template>
<script>

export default {
  name: "AboutFond",
  props: {
    msg: String,
  },

  /*
mounted () {
    const TPF = document.getElementById("payform-tinkoff");

    TPF.addEventListener("submit", function (e) {
        e.preventDefault();
        const {description, amount, email, phone, receipt} = TPF;

        if (receipt) {
            if (!email.value && !phone.value)
                return alert("Поле E-mail или Phone не должно быть пустым");

            TPF.receipt.value = JSON.stringify({
                "EmailCompany": "mail@mail.com",
                "Taxation": "patent",
                "Items": [
                    {
                        "Name": description.value || "Оплата",
                        "Price": amount.value + '00',
                        "Quantity": 1.00,
                        "Amount": amount.value + '00',
                        "PaymentMethod": "full_prepayment",
                        "PaymentObject": "service",
                        "Tax": "none"
                    }
                ]
            });
        }
        //pay(TPF);
        this.pay(TPF);
    })
  }
  */
};
</script>
<style lang="scss">

.payform-tinkoff {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 2px auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        max-width: 250px;
    }
    .payform-tinkoff-row {
        margin: 2px;
        border-radius: 4px;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        -webkit-transition: 0.3s;
        -o-transition: 0.3s;
        transition: 0.3s;
        border: 1px solid #DFE3F3;
        padding: 15px;
        outline: none;
        background-color: #DFE3F3;
        font-size: 15px;
    }
    .payform-tinkoff-row:focus {
        background-color: #FFFFFF;
        border: 1px solid #616871;
        border-radius: 4px;
    }
    .payform-tinkoff-btn {
        background-color: #FBC520;
        border: 1px solid #FBC520;
        color: #3C2C0B;
    }
    .payform-tinkoff-btn:hover {
        background-color: #FAB619;
        border: 1px solid #FAB619;
    }
    </style>