<template>
  <div>
    <section id="closedItem" style="background-color: white">
      <div class="container" style="text-align: center; padding-top: 60px">
        <br />
        <!--    <span
          style="
            color: red;
            font-size: 36px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          "
        >
          Прошедшие события
        </span>
      -->

        <br />
        <div
          class="image-overlay"
          style="width: 400px; display: block; position: relative"
        >
          <img style="width: 400px" src="../assets/cyndyk_itogo.jpg" />
          <div
            class="text-overlay"
            style="
              display: block;
              position: absolute;
              left: 50px;
              top: 100px;
              color: white;
            "
          >
            <span style="font-size: 42px">12 020</span
            ><span style="font-size: 24px"> рублей </span><br />
            <span style="font-size: 18px">собрали в "Сундук"</span
            ><span style="font-size: 42px"> 16.12.2023</span>
          </div>

          <br />
          <br />
        </div>
        <a
          style="color: red; font-size: 24px; border-radius: 4px"
          href="https://disk.yandex.ru/i/Cl_K5aZ-Q_tkVg"
          target="_blank"
        >
          Положение
        </a>
        <br />
        <a
          style="color: red; font-size: 24px; border-radius: 4px"
          href="https://vk.link/sunduk_2023"
          target="_blank"
        >
          Отчет о мероприятии
        </a>
        <br />
        <br />
      </div>
    </section>

    <section id="nextcyndyk">
      <div
        class="container"
        style="display: inline-table; text-align: center; align-items: center"
      >
        <br />
        <br />
        <img
          style="
            align-items: center;
            text-align: center;
            width: 100%;
            transition: 0.5s ease all;
            margin-top: 0px;
            padding-bottom: 20px;
            background-color: white;
          "
          src="../assets/kak_eto_rabotaet.png"
        />

        <br />
        <section id="pointcyndyk" style="background-color: red; height: 30vh; display: inline-table; width: 100%;">
          <br />

          <div
            class="container"
            style="text-align: center; justify-content: center"
          >
            <br />
            <span
              style="
                color: white;
                font-size: 36px;
                font-family: Verdana, Geneva, Tahoma, sans-serif;
              "
            >
              Точки сбора книг для "Сундука":<br />
              Станица Зольская Дом Культуры<br />
              Благотворительный Фонд "Алая лента"<br />
              ул. Калинина, д. 134
            </span>
            <br />
        <br />
          </div>
        </section>
        <br />
        <section id="spay">
          <div class="hero-text container">
            <br />
            <div class="container" style="text-align: center">
              <button
                v-scroll-to="'#spay'"
                style="
                  background-color: white;
                  text-align: center;
                  align-items: center;
                  border: 1px solid;
                  border-color: red;
                  color: red;
                  width: 300px;
                  height: 50px;
                  font-size: 24px;
                  border-radius: 4px;
                "
              >
                Поддержать "СУНДУК"
              </button>
            </div>
            <br />

            <form
              class="payform-tinkoff"
              name="payform-tinkoff"
              onsubmit="pay(this); return false;"
            >
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="terminalkey"
                value="1699375114876"
              />
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="frame"
                value="false"
              />
              <input
                class="payform-tinkoff-row"
                type="hidden"
                name="language"
                value="ru"
              />
              <label style="color: #000"
                >Сумма пожертвования<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="Сумма"
                  name="amount"
                  required
              /></label>
              <input
                class="payform-tinkoff-row"
                type="hidden"
                placeholder="Номер"
                name="order"
              />
              <label style="color: #000"
                >Назначение платежа<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="Добровольное пожертование"
                  name="description"
                  disabled="true"
              /></label>
              <label style="color: #000"
                >Плательщик<input
                  class="payform-tinkoff-row"
                  type="text"
                  placeholder="ФИО плательщика"
                  name="name"
              /></label>
              <label style="color: #000"
                >Email:<input
                  class="payform-tinkoff-row"
                  type="email"
                  placeholder="E-mail"
                  name="email"
              /></label>
              <label style="color: #000"
                >Телефон:<input
                  class="payform-tinkoff-row"
                  type="tel"
                  placeholder="Контактный телефон"
                  name="phone"
              /></label>
              <input
                class="payform-tinkoff-row payform-tinkoff-btn"
                type="submit"
                value="Поддержать"
                textarea
                :disabled="!oferta_checked"
              />
            </form>
            <label style="color: #000; vertical-align: top">
              <input
                type="checkbox"
                name="checkbox-example"
                v-model="oferta_checked"
                v-on:click="oferta_check"
              />
              <span class="custom-checkbox" style="vertical-align: top"></span>
              Я согласен с
              <a href="../oferta.pdf" style="text-decoration: none">
                условиями оферты</a
              ><br />
              и ознакомлен с
              <a
                href="../policy.pdf"
                target="_blank"
                style="text-decoration: none"
                >политикой обработки персональных данных</a
              >
            </label>
            <br />
            <br />
          </div>
        </section>
        <img
          style="
            align-items: center;
            text-align: center;
            max-width: 400px;
            width: 40%;

            padding-top: 96px;
          "
          src="../assets/cyndyk1.jpg"
        />

        <!--
        <span
          style="
            font-size: 36px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          "
        >
          
        "Сундук"</span
        >
        -->
        <br />
        <span
          style="
            font-size: 48px;
            font-style: bold;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
          "
        >
          16 декабря 2023 года <br />
          c 9:00 до 14:00
        </span>
        <br />
        <h1>Дом Культуры станицы Зольской</h1>
        <br />
      </div>
      <div
        class="container"
        style="width: 100%; text-align: center; padding: 20px"
      >
        Дорогие друзья, у нас к вам хорошая новость!
        <p>
          В эту субботу, 16 декабря, в ст. Зольской пройдет первое мероприятие
          БФ "Алая лента" - благотворительная гаражная распродажа «СУНДУК»,
          посвященная книгам и произведениям искусства. Все собранные средства
          будут направлены на закупку продуктов для приготовления сухих супов,
          которые очень ждут наши ребята "Za ленточкой". <br />«СУНДУК» - это
          отличная возможность собрать деньги для благого дела, а также найти
          интересные книги для своей домашней библиотеки, пообщаться с другими
          любителями чтения, поделиться своими впечатлениями о прочитанном и,
          возможно, найти новых друзей. Все книги будут представлены по очень
          доступным ценам, так что каждый сможет найти для себя что-то
          интересное и полезное.
        </p>
        <p>
          Участие в гаражной распродаже – это возможность внести свой вклад в
          благотворительность. Уже сейчас мы открыли сбор книг и вы можете
          принести свои экземпляры для распродажи. Наверняка, у вас есть книги,
          которые вы уже прочитали, или они занимают лишнее место, или просто не
          нужны. Приносите их к нам, а мы их рассортируем, если нужно, приведём
          в порядок, и выставим на распродажу. <br />Ваши книги помогут нам
          собрать необходимую сумму для закупки продуктов. Сегодня важна каждая
          книга. <br />Если вы хотите поддержать благотворительность, найти
          интересные книги или просто провести время в приятной компании,
          приходите на нашу благотворительную гаражную распродажу книг. «СУНДУК»
          будет проходить с 9 утра до 14 часов дня, и мы будем рады видеть вас
          там!
        </p>
        Следите за нашими анонсами в социальных сетях и местных чатах!
        <br />Место сбора книг: ст. Зольская, Дом Культуры. <br />Ждём
        волонтёров, для работы на распродаже.<br />
        Заявки направляйте нам на электронную почту<br />
        bf.alayalenta@yandex.ru
      </div>
    </section>
    <br />
    <section id="imgcyndyk">
      <div class="container" style="align-items: center; text-align: center">
        <img
          style="max-width: 500px; width: 100%; margin-top: 0px; padding: 0px"
          src="../assets/cyndyk2.jpg"
        />
      </div>
    </section>
    <br />
    <br />
    <section id="howtocyndyk">
      <div class="container" style="font-size: 24px; padding: 0">
        <div class="row col-12" style="justify-content: center">
          <div class="col" style="text-align: center">
            Ярмарка новогодних подарков
          </div>
          <div
            class="col"
            style="
              border-left: 5px solid;
              border-color: red;
              text-align: center;
            "
          >
            Мастер-классы от рукодельниц
          </div>
          <div
            class="col"
            style="
              border-left: 5px solid;
              border-color: red;
              text-align: center;
            "
          >
            Сессия нейрографики
          </div>
          <div
            class="col"
            style="
              border-left: 5px solid;
              border-color: red;
              text-align: center;
            "
          >
            Художественное чтение волшебной сказки
          </div>
        </div>
      </div>
      <br />
      <br />
    </section>
  </div>
</template>
  
  
  <script>
// @ is an alias to /src

export default {
  name: "CyndykItem",
  components: {},
  data() {
    return {
      oferta_checked: false,
    };
  },
  oferta_check() {
    this.oferta_checked = !this.oferta_checked;
  },
};
</script>
  
  <style lang="scss" scoped>
.container {
  margin: 0;
  padding: 0;
  align-items: center;
}

.hero {
  background-attachment: fixed;
  position: relative;
  // height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: black;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }
  }

  .payform-tinkoff {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 2px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 250px;
    align-items: center;
  }
  .payform-tinkoff-row {
    margin: 2px;
    border-radius: 4px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    border: 1px solid #dfe3f3;
    padding: 15px;
    outline: none;
    background-color: #dfe3f3;
    font-size: 15px;
    width: 100%;
  }
  .payform-tinkoff-btn {
    background-color: red;
    border: 1px solid;
    border-color: #ffffff;
    color: #ffffff;
    content: "Подтвердите своё согласие с офертой";
  }
  .payform-tinkoff-btn:hover {
    background-color: #fab619;
    border: 1px solid #fab619;
  }
  .payform-tinkoff-btn:disabled {
    background-color: #fab619;
    border: 1px solid grey;
  }
  input[type="checkbox"] {
    display: none;
  }
  .payform-tinkoff-row:focus {
    background-color: #ffffff;
    border: 1px solid #616871;
    border-radius: 4px;
  }
  .custom-checkbox {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid black;
    cursor: pointer;
    color: black;
  }
  input[type="checkbox"]:checked + .custom-checkbox {
    background-color: red;
    border-color: red;
  }
}
</style>