<template>
    <div>
      <div class="container" style="padding-top: 106px;">
    <br/>
            <div class="row col-12"
            style="font-size: 32px; text-align: center; font-family: Verdana, Geneva, Tahoma, sans-serif;">
        <span>Документы фонда</span>
    </div>
        <br>
        <a href="../oferta.pdf" target="_blank" tag="button">Оферта</a>
        <br>
        <a href="../Политика конфиденциальности.pdf" target="_blank" tag="button">Политика обработки персональных данных</a>
        <br>
        <a href="../UchDokum.pdf" target="_blank" tag="button">Устав фонда</a>
        <br/>
        
        <a href="../SvidUchetU.pdf" target="_blank" tag="button">Свидетельство о постановке на учет в ФНС</a>
        <br/>
        
        <a href="../Оплата с помощью банковской карты.pdf" target="_blank" tag="button">Оплата с помощью банковской карты</a>
        <br/>
              
     
      </div>
</div>
    
  </template>
  <script>
  
import axios from 'axios'
//import { Collapse } from 'vue-collapsed'

var base_url = "https://cloud-api.yandex.net/v1/disk/public/resources/download?"
var load_url = "https://disk.yandex.ru/i/m7xxDfLlPhZBgw"

export default {
    name: "DocsFond",
    components: {
     // Collapse
    },
        props: {
      msg: String,
    },
  
    data() {return {
      lists: [{id: 1, name: "name1"},{id: 2, name: "name2"}],

    }},
      
      methods: {
        
      
  async loadFile() {
              await axios({
                    url: base_url + 'public_key=' + load_url,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fURL = document.createElement('a');
    
                     fURL.href = fileURL;
                     fURL.setAttribute('download', 'file.pdf');
                     document.body.appendChild(fURL);
    
                     fURL.click();
                });
          }
      }
    };

   
</script>

  <style>
  
  

.v-collapse {
  transition: height 300ms cubic-bezier(0.33, 1, 0.68, 1);
}

      </style>