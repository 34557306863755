<template>
  <div
    class="container"
    style="
      padding-top: 96px;
      text-align: center;
      padding-left: 50px;
      padding-right: 50px;
    "
  >
    <br />
    <span
      style="font-size: 32px; font-family: Verdana, Geneva, Tahoma, sans-serif"
      >Волонтёрам</span
    >
    <br />
    <br />
    Необходимые документы для заполнения
      
      <br>


        <a href="../anketa_volunteer.docx" target="_blank" tag="button">Анкета волонтёра</a>
        <br>


  </div>
</template>
    
    <script>
export default {
  name: "VolunteerItem",
  props: {
    msg: String,
  },
};
</script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
a {
  color: #42b983;
}
</style>
    