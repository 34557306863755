<template>
  <header :class="{ 'scrolled-nav': scrolledNav }">
    <nav>
      <!--<div class="branding">
        <a href="https://alayalenta.ru"><img src="../assets/bfal.jpg" alt="" /></a>
      </div>-->
     <ul v-show="!this.isMobNav" class="navigation">
        <li><router-link class="link" to="/">Главная</router-link></li>
        <li><router-link class="link" to="/cyndyk">Сундук</router-link></li>
        <li><router-link class="link" to="/step">Шаг в будущее</router-link></li>
        <li><router-link class="link" to="/program">Программы</router-link></li>
        <li><router-link class="link" to="/sovet">Совет фонда</router-link></li>
        <li><router-link class="link" to="/report">Отчеты</router-link></li>
        <li><router-link class="link" to="/about">Реквизиты</router-link></li>
        <!--<li><router-link class="link" to="/docs/oferta">Оферта</router-link></li>-->
        <li><router-link class="link" to="/docs">Документы</router-link></li>
        <li><router-link class="link" to="/needhelp">Получить помощь</router-link></li>
        <li><router-link class="link" to="/volunteer">Волонтёрам</router-link></li>
        <!--<li><router-link class="link" to="/profile"> Профиль</router-link></li>-->
      
       
        
      </ul>
      <div class="icon">
        <i
          @click="toggleMobileNav"
          v-show="this.isMobNav"
          class="far fa-bars"
          :class="{ 'icon-active': this.mobileNav }"
        ></i>
      </div>

      <transition name="isMobNav-nav">
        <ul v-show="this.mobileNav" class="dropdown-nav" @click="CloseMobileNav">
          <li><router-link class="link" to="/">Главная</router-link></li>
          <li><router-link class="link" to="/cyndyk"> Сундук</router-link></li>
          <li><router-link class="link" to="/step">Шаг в будущее</router-link></li>
          <li><router-link class="link" to="/program">Программы</router-link></li>
          <li><router-link class="link" to="/sovet">Совет фонда</router-link></li>
          <li><router-link class="link" to="/report">Отчеты</router-link></li>
          <li><router-link class="link" to="/about">Реквизиты</router-link></li>
          <!--<li><router-link class="link" to="/docs/oferta">Оферта</router-link></li>-->
          <li><router-link class="link" to="/docs">Документы</router-link></li>
          <!--li><router-link class="link" to="/profile"> Профиль</router-link></li>-->
          <li><router-link class="link" to="/needhelp">Получить помощь</router-link></li>
          <li><router-link class="link" to="/volunteer">Волонтёрам</router-link></li>          
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>


export default {
  name: "NaivgationBar",
  
  data() {
    return {
      scrolledNav: null,
      isMobNav: null,
      mobileNav: null,
      
    };
  },
  
  created() {
    //console.log(useCheckScreen().isMob._value);
  //this.isMobNav = this.chScreen;
  //this.isMobNav = this.chScreen;
 
    this.mobileNav =  !this.chScreen;
    
    window.addEventListener("load", this.chScreen);
    window.addEventListener("resize", this.chScreen);
       
    
  },
  mounted() {
    
    this.isMobNav = this.chScreen;
    //window.addEventListener('load', chScreen);
    //window.addEventListener("resize", chScreen);
    //window.addEventListener("scroll", this.updateScroll);
  //  window.addEventListener("resize", useCheckScreen);
    
  },
  methods: {
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    CloseMobileNav() {
      this.mobileNav = false;
    },

    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
    },

    chScreen() {
     // console.log("3.0");
      if (window.innerWidth <= 750) {
        this.isMobNav = true;
    }
    else {
      this.isMobNav = false;
    }
   //   console.log("3.1");
      
      return this.isMobNav;
    }
    }
  , 
  /*watch: {
    '$route' () {
      this.isMobNav = false;
    }
  }
  ,*/
};
</script>

<style lang="scss" scoped>
header {
  background-color: red;
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: black;

  nav {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    transition: 0.5s ease all;
    width: 100%;
    margin: 0 auto;
    @media (min-width: 1140px) {
      max-width: 1140px;
    }

    ul,
    .link {
      font-weight: 500;
      color: white;
      list-style: none;
      text-decoration: none;
    }
    li {
      //text-transform: uppercase;
      padding: 16px;
     // margin-left: 16px;
    }
    .link {
      font-size: 14px;
      transition: 0.5s ease all;
      padding-bottom: 4px;
      border-bottom: 1px solid transparent;

      &:hover {
        color: black;

        border-color: red;
      }
    }
    .branding {
      display: flex;
      align-items: center;

      img {
        width: 50px;
        transition: 0.5s ease all;
      }
    }
    .navigation {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    .icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 300px;
      height: 100%;
      padding: 10px;
    }
    i {
      cursor: pointer;
      font-size: 24px;
      transition: 0.8s ease all;
    }

    .icon-active {
      transform: rotate(180deg);
    }

    .dropdown-nav {
      display: flex;
      flex-direction: column;
      position: fixed;
      width: 100%;
      max-width: 200px;
      height: 100%;
      background-color: #fff;
      top: 0;
      left: 0;

      li {
        margin-left: 0;
        .link {
          color: red;
        }
      }
    }
  }
  .isMobNav-nav-enter-active,
  .isMobNav-nav-leave-active {
    transition: 1s ease all;
  }

  .isMobNav-nav-enter-from,
  .isMobNav-nav-leave-to {
    transform: translateX(-250px);
  }

  .isMobNav-nav-enter-to {
    transform: translateX(0);
  }
}
.scrolled-nav {
  background-color: red;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  nav {
    padding: 12px;

    .branding {
      img {
        width: 40px;
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
  }

  .overlayX {
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    bottom: 0;
    background: rgba(115, 113, 252, 0.8);
    z-index: 9998;
    transition: opacity 0.4s, width 0s 0.4s;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    opacity: 0;
  }
  .overlay_active {
    opacity: 1;
    width: 100%;
    transition: opacity 0.4s, width 0s 0s;
  }
}
</style>
