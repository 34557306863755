<template>
    <div class="container" style="padding-top: 96px; text-align: center; padding-left: 50px; padding-right: 50px;">
        <br/>
      <span style="font-size: 32px;  font-family: Verdana, Geneva, Tahoma, sans-serif;">ШАГ В БУДУЩЕЕ</span>
      <br/>
      <br/>
      <span>
    <p>Стипендиальная программа
«Шаг в будущее».</p>
<br/>
<p>Программа поможет талантливым детям
из малообеспеченных семей
поступить в высшие учебное заведение.</p>
<p>
Поддержите программу сегодня, чтобы 
завтра наши выпускники смогли сделать 
уверенный шаг в своё будущее.</p>

Карта помощи: 2200 7003 0580 5227
      </span>
      <br/>
      <hr>
      <br/>
      <img
      style="
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 500px;
        transition: 0.5s ease all;
        margin-top: 0px;
        padding-bottom: 0px;
        background-color: white;
      "
      src="../assets/step1.jpg"
    />
    <br/>
    <br/>
      <span>
        <p>Слушай - Читай - Помогай
        </p>

Благотворительные чтения проводятся 
в рамках стипендиальной программы
Вы узнаете историю произведения, которое будет прочитано в рамках мероприятия.
Нарисуете картину по мотивам произведения, 
При желании создадите книжку, которую можно будет унести с собой за пожертвование в 250р. 
Часть денег пойдет в пожертвование Никольскому храму и часть в
 фонд стипендиальной программы 
«Шаг в будущее»
Для чтения книг мы приглашаем творческих людей, активистов, волонтёров, артистов, музыкантов, писателей, активных жителей района, руководителей администрации и предприятий.
      </span>
    </div>
  </template>
    
    <script>
  export default {
    name: "StepItem",
    props: {
      msg: String,
    },
  };
  </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
  a {
    color: #42b983;
  }
  </style>
    