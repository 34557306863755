<template>
    <div class="container" style="padding-top: 96px; padding-left: 50px; padding-right: 50px;">

      <br/>
      <span style="font-size: 32px;  font-family: Verdana, Geneva, Tahoma, sans-serif;">Как получить помощь:</span>
      <br/>
      <br/>
<p>Распечатать и заполнить Анкету с подробной информацией о необходимой помощи. </p>
<p>Распечатать и подписать Согласие на обработку персональных данных. </p>
<p>Заполненные и подписанные бланки необходимо  прислать почтовым отправлением по адресу: 357324, Россия, Ставропольский край, Кировский р-н, ст-ца Зольская, ул Калинина, д 134, БФ «Алая лента».</p>

 
<p>*Если Вы получаете помощь от другого благотворительного Фонда, просим сообщить об этом.</p>

<p>**На почтовом конверте адрес, фамилия, имя, отчество должны быть написаны полностью, разборчиво и аккуратно.</p>

<p>***Обращаем внимание, что Фонд имеет право отказать в помощи без объяснения причин.</p>

<br>

Необходимые документы для заполнения
      
      <br>


        <a href="../anketa.docx" target="_blank" tag="button">Анкета</a>
        <br>
        <a href="../soglasie.docx" target="_blank" tag="button">Согласие на обработку персональных данных</a>
        <br>
        
  
    </div>
  </template>
    
    <script>
  export default {
    name: "NeedHelpItem",
    props: {
      msg: String,
    },
  };
  </script>
    
    <!-- Add "scoped" attribute to limit CSS to this component only -->
    <style scoped>
  a {
    color: #42b983;
  }
  </style>
    